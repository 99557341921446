(function ($) {
  $('#press-carousel').owlCarousel({
    loop: true,
    margin: 3,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    },
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    dots: false
  });

  $('#partners-carousel').owlCarousel({
    loop: true,
    margin: 3,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    dots: false
  })

  $('#customers-carousel').owlCarousel({
    loop: false,
    margin: 3,
    autoplay: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      1000: {
        items: 5
      }
    },
    nav: false,
    dots: false,
  })

  $(document).ready(function () {
    $('.twentytwenty-container').twentytwenty({
      defaultOffsetPct: 0.9
    });
  });

  $(window).load(function () {
    $(window).trigger("resize.twentytwenty");
  });

  "use strict";
  $(".carousel-inner .item:first-child").addClass("active");
  /* Mobile menu click then remove
  ==========================*/
  $(".mainmenu-area #mainmenu li a").on("click", function () {
    $(".navbar-collapse").removeClass("in");
  });
  /*WoW js Active
  // =================*/
  // new WOW().init({
  //   mobile: true,
  // });
  /* Scroll to top
  ===================*/
  // $.scrollUp({
  //   scrollText: '<i class="fa fa-angle-up"></i>',
  //   easingType: 'linear',
  //   scrollSpeed: 900,
  //   animation: 'fade'
  // });
  /* testimonials Slider Active
  =============================*/
  $('.testimonials').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1000,
    navText: ['<i class="ti-arrow-left"></i>', '<i class="ti-arrow-right" ></i>'],
    items: 1
  });
  /* testimonials Slider Active
  =============================*/
  $('.screen-slider').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1000,
    navText: ['<i class="ti-arrow-left"></i>', '<i class="ti-arrow-right" ></i>'],
    items: 1,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    center: true,
  });
  /* testimonials Slider Active
  =============================*/
  // $('.clients').owlCarousel({
  //     loop: true,
  //     margin: 30,
  //     responsiveClass: true,
  //     nav: true,
  //     autoplay: true,
  //     autoplayTimeout: 4000,
  //     smartSpeed: 1000,
  //     navText: ['<i class="ti-arrow-left"></i>', '<i class="ti-arrow-right" ></i>'],
  //     responsive: {
  //         0: {
  //             items: 3,
  //         },
  //         600: {
  //             items: 4
  //         },
  //         1000: {
  //             items: 6
  //         }
  //     }
  // });
  /*--------------------
     MAGNIFIC POPUP JS
     ----------------------*/
  var magnifPopup = function () {
    $('.work-popup').magnificPopup({
      type: 'image',
      removalDelay: 300,
      mainClass: 'mfp-with-zoom',
      gallery: {
        enabled: true
      },
      zoom: {
        enabled: true, // By default it's false, so don't forget to enable it

        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function

        // The "opener" function should return the element from which popup will be zoomed in
        // and to which popup will be scaled down
        // By defailt it looks for an image tag:
        opener: function (openerElement) {
          // openerElement is the element on which popup was initialized, in this case its <a> tag
          // you don't need to add "opener" option if this code matches your needs, it's defailt one.
          return openerElement.is('img') ? openerElement : openerElement.find('img');
        }
      }
    });
  };
  // Call the functions
  magnifPopup();

  //Background Parallax
  $('.header-area').parallax("50%", -0.4);
  $('.price-area').parallax("50%", -0.5);
  $('.testimonial-area').parallax("10%", -0.2);


  $('#accordion .panel-title a').prepend('<span></span>');


  //Function to animate slider captions
  function doAnimations(elems) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';

    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }

  //Variables on page load
  var $myCarousel = $('.caption-slider'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");

  //Initialize carousel
  $myCarousel.carousel();

  //Animate captions in first slide on page load
  doAnimations($firstAnimatingElems);

  //Pause carousel
  $myCarousel.carousel('pause');


  //Other slides to be animated on carousel slide event
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $(document).on('click','.navbar-collapse.in',function(e) {
    if( $(e.target).is('a') ) {
      $(this).collapse('hide');
    }
  });
  // Select all links with hashes
  $('.mainmenu-area a[href*="#"]')
  // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
            ;
          });
        }
      }
    });


  /* Preloader Js
  ===================*/
  $(window).on("load", function () {
    $('.preloader').fadeOut(500);
  });
})(jQuery);
